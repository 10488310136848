<template>
  <div id="app">
    <router-view   v-wechat-title='$route.meta.title'> </router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
