import Dexie from 'dexie';

const db = new Dexie('db');
db.version(1).stores({

  plan: `++localID,needUpdate,id,userID,title,content,imgList,startDate,startTime,
  endTime,lockMinute,isDone,doneTime,doneDate,sortInDate,createTime,updateTime,
  isDeleted,isPrimary,isBlock,isHideByUs,reportNum,auditStatus,collectionID,parentID,childCount,remindList`,

  planCollection: `++localID,needUpdate,id,userID,collectionName,collectionColor,collectionIcon,classify,sortSelf,
  folderID,sortInFolder,createTime,updateTime,isDeleted,isClosed`
});
db.version(2).stores({

  plan: `++localID,needUpdate,id,userID,title,content,imgList,startDate,startTime,
  endTime,lockMinute,isDone,doneTime,doneDate,sortInDate,createTime,updateTime,
  isDeleted,isPrimary,isBlock,isHideByUs,reportNum,auditStatus,collectionID,parentID,childCount,remindList,repeatFlag,repeatPlanID`,

  planCollection: `++localID,needUpdate,id,userID,collectionName,collectionColor,collectionIcon,classify,sortSelf,
  folderID,sortInFolder,createTime,updateTime,isDeleted,isClosed`
})
db.open()
export default db;

// 具体语法参考官网API文档 https://dexie.org/docs/API-Reference
