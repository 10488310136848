import encryptUtil from './encrypt'

export default {
  getSign: function () {
    let code = ''
    for (let i = 0; i < 6; i++) {
      let ran = Math.floor(Math.random() * 10); // returns a random integer from 0 to 9
      code = code + ran
    }

    let time = Date.now()
    let ss = time + '&studyRoom&' + code
    let encrypt = encryptUtil.AESEnc(ss, null)
    let map = {
      SIGN: encrypt,
      CODE: code
    }
    return map
  }
}
