import request from '@/utils/request'

export function getUserByAccount (params) {
  return request({
    url: 'common/api/user/getUserByAccount',
    method: 'post',
    data: {
      data: params
    }
  })
}
