import crypto from 'crypto-js'

export default {
  key: '1234567890abcdef', // 默认秘钥

  AESEnc: function (content, key) {
    if (!key) {
      key = this.key
    }
    key = crypto.enc.Utf8.parse(key) // 加密密钥
    var srcs = crypto.enc.Utf8.parse(content)
    var encrypted = crypto.AES.encrypt(srcs, key, { iv: key, mode: crypto.mode.ECB })
    return encrypted.toString()
  },

  AESDec: function (content, key) {
    if (!key) {
      key = this.key
    }
    key = crypto.enc.Utf8.parse(key) // 解密密钥
    let decrypted = crypto.AES.decrypt(content, key, { iv: key, mode: crypto.mode.ECB })
    let decryptedStr = decrypted.toString(crypto.enc.Utf8)
    return decryptedStr
  }
}
