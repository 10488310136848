import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import headerUtil from '@/utils/header'
import storage from '@/store/storage';

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  // baseURL: 'http://192.168.101.104:9886/', // 家里
  // baseURL: 'http://10.111.16.61:9886/', // 公司
  // baseURL: 'http://127.0.0.1:9886/', // 服务器
  // baseURL: 'https://test.gaokaocal.com/studyroom/', // 服务器
  baseURL: 'https://www.gaokaocal.com/studyroom/', // 服务器
  timeout: 15000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  // console.log('Starting Request', JSON.stringify(config, null, 2))

  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/json'
  }

  if (config.url.indexOf('getUserByAccount') > -1) {
    // do nothing 无需设置token
  } else {
    let user = storage.get('user')
    if (user != null) {
      config.headers['token'] = storage.get('user').token
    } else {
      Message.error('请重新登录( request js错误)')
    }
  }

  // config.headers['Access-Control-Allow-Origin'] = '*'
  // config.headers['Access-Control-Allow-Methods'] = 'POST, GET, PUT, OPTIONS, DELETE'
  let map = headerUtil.getSign()
  config.headers['sign'] = map.SIGN
  config.headers['code'] = map.CODE
  config.headers['key'] = map.SIGN + map.CODE // 没对key进行校验，仅判断是否为空

  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log(res)
    if (res.code !== 0) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error)// for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
