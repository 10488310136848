import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { router } from './router/index'
import VueWechatTitle from 'vue-wechat-title'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import db from '../src/config/dbConfig'
import store from '../src/store/index'

Vue.use(Router)
Vue.use(VueWechatTitle)
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.db = db

Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
