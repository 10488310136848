const todo = {
  state: {
    currentType: 'ALL',
    currentCollection: ''
  },
  mutations: {
    SET_CURRENT_TYPE (state, payload) {
      state.currentType = payload
    },
    SET_CURRENT_COLLECTION (state, payload) {
      state.currentCollection = payload
    }
  },
  actions: {
    SET_CURRENT_TYPE (context, payload) {
      context.commit('SET_CURRENT_TYPE', payload)
    },
    SET_CURRENT_COLLECTION (context, payload) {
      context.commit('SET_CURRENT_COLLECTION', payload)
    }
  }
}

export default todo
